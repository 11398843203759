<gipi-abstract-form id="dialogActionsCheckReceived"
                    name="dialogActionsCheckReceived"
                    [dialog]="true">

    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogActionsCheckReceived">
        {{ setTitle() }}
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxFlexFill>
            <gipi-datepicker [label]="setLabelDate()"
                             [required]="true"
                             [disabled]="loading"
                             [(ngModel)]="moveDate"
                             [fxFlex]="(data?.action === 'COMPENSATE_DEPOSIT_CHECK') ? '100' : '30'"
                             fxFlex.lt-md="100">
            </gipi-datepicker>
            <itss-select-entity-paged label="Conta bancária"
                                      *ngIf="((data?.action !== 'COMPENSATE_DEPOSIT_CHECK') && (data?.action !== 'REFUND'))"
                                      [required]="true"
                                      [disabled]="loading"
                                      [(model)]="bankAccount"
                                      [nextBatchFn]="bankAccountFindByTypeFn"
                                      [property]="'description'"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>

            <itss-select-entity-paged label="Motivo da devolução"
                                      *ngIf="(data?.action === 'REFUND')"
                                      [required]="true"
                                      [disabled]="loading"
                                      [(model)]="reasonRefund"
                                      [nextBatchFn]="reasonRefundFindByValueFn"
                                      [property]="'description'"
                                      fxFlex
                                      fxFlex.lt-md="100">
            </itss-select-entity-paged>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row"
         [fxLayoutAlign]="(data?.action === 'COMPENSATE_DEPOSIT_CHECK') ? 'center end' : 'end end'"
         fxLayoutGap="16px">
        <gipi-button label="Confirmar"
                     gipi-primary
                     id="btnSave"
                     [loading]="loading"
                     [disabled]="loading"
                     (click)="confirm()"
                     [fxFlex]="(data?.action === 'COMPENSATE_DEPOSIT_CHECK') ? '40' : '32'"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     (click)="close('NONE')"
                     type="raised"
                     [fxFlex]="(data?.action === 'COMPENSATE_DEPOSIT_CHECK') ? '40' : '32'"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract-form>