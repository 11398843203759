import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import * as moment_ from 'moment';
const moment = moment_;

import { FinancialBillAssessment } from '@gipi-financial/bill/models/bill-assessment.model';
import { FinancialCostCenterAssessmentsService } from '@gipi-financial/cost-center-assessment/services/cost-center-assessments.service';
import { FinancialCostCenter } from '@gipi-financial/cost-center/models/cost-center.model';
import { FinancialCostCenterService } from '@gipi-financial/cost-center/services/cost-center.service';
import { FinancialPostingCategorySelectDTO } from '@gipi-financial/posting-category/models/dto/posting-category-select.dto';
import { FinancialPostingCategory } from '@gipi-financial/posting-category/models/posting-category.model';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { FinancialReceivementCardAdministrator } from '@gipi-financial/receivement-card-administrator/models/receivement-card-administrator.model';
import { FinancialReceivementCard } from '@gipi-financial/receivement-card-administrator/models/receivement-card.model';
import { FinancialReceivementCardService } from '@gipi-financial/receivement-card-administrator/services/receivement-card.service';
import { APP_MESSAGES, ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, GIPISortModel, GIPIUuid, INJECTOR, NumberUtil, ObjectUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

interface Receivement {
    originalValue: number;
    discountRate: number;
    discountAnticipation: number;
    amountNet: number;
}

export interface ReceivementCardAdministratorFormData {
    ids: GIPIUuid[];
    anticipation?: boolean;
}

@Component({
    selector: 'gipi-receivement-card-administrator-form-dialog',
    templateUrl: './receivement-card-administrator-form-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class ReceivementCardAdministratorFormDialogComponent extends GIPIAbstractComponent implements OnInit, OnDestroy {

    private _receivementCardList: FinancialReceivementCard[] = [];
    private _receivementCardAdministratorList: FinancialReceivementCardAdministrator[] = [];

    public costCenterColumns: TableColumnDTO[] = this._createTableColumnsCostCenter();

    public receivementDate: Date = moment().startOf('day').toDate();

    public receivementPostingCategory: FinancialPostingCategorySelectDTO = null;

    public receivementAmounts: Receivement = {
        originalValue: 0,
        discountRate: 0,
        discountAnticipation: 0,
        amountNet: 0,
    };

    public isAnticipation: boolean = false;

    public costCenterExludList: FinancialCostCenter[] = [];

    public assessment: FinancialBillAssessment = new FinancialBillAssessment();

    public assessmentList: FinancialBillAssessment[] = [];

    postingCategoryFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialPostingCategorySelectDTO> = await this._postingCategoryService.findByValue<FinancialPostingCategorySelectDTO>(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'DEBIT', true).toPromise();
        return result;
    };
    postingCategoryFn = (obj: FinancialPostingCategorySelectDTO) => `${obj.fullCode} - ${obj.description}`;

    costCenterFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialCostCenter> = await this._costCenterService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, true).toPromise();
        this._removeCostCenter(result.content);
        return result;
    };
    costCenterFn = (obj: FinancialCostCenter) => `${obj.fullCode} - ${obj.description}`;

    public get totalPercentageAssessment(): number {
        return this.assessmentList.reduce((sum, e) => sum += e.percentage, 0);
    }

    public get amountNetDiscount(): number {
        return this._receivementCardAdministratorList.reduce((sum, e) => sum += (e.discountRate + e.discountAnticipation), 0);
    }

    constructor(
        protected service: FinancialReceivementCardService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _postingCategoryService: FinancialPostingCategoryService,
        private _costCenterAssessmentService: FinancialCostCenterAssessmentsService,
        private _costCenterService: FinancialCostCenterService,
        @Inject(MAT_DIALOG_DATA) public data: ReceivementCardAdministratorFormData = { ids: [] },
        public dialogRef: MatDialogRef<ReceivementCardAdministratorFormDialogComponent>,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._findReceivementsCard();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private _createTableColumnsCostCenter(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('code')
                .name('Código')
                .align('center center')
                .width(10)
                .align('center center')
                .value((obj: FinancialBillAssessment) => obj.costCenter.fullCode)
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Centro de custo')
                .value((obj: FinancialBillAssessment) => obj.costCenter.description)
                .build(),
            TableColumnBuilder.instance()
                .property('percentage')
                .name('Percentual')
                .value((obj: FinancialBillAssessment) => obj.percentage + '%')
                .width(10)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .value((obj: FinancialBillAssessment) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .width(10)
                .align('center center')
                .build()
        ];
    }

    private async _findReceivementsCard(): Promise<void> {
        try {
            if (
                ObjectUtil.isNull(this.data) ||
                (!ObjectUtil.isNull(this.data) && ArrayUtil.isEmpty(this.data.ids))
            ) {
                return;
            }

            this.isAnticipation = this.data.anticipation;

            const receivementCardList: FinancialReceivementCard[] = await this.service.findByIds(this.data.ids).toPromise().catch(error => {
                this.loading = false;
                this.addErrorMessage(error);
                return [];
            });

            if (!ArrayUtil.isEmpty(receivementCardList)) {
                this._receivementCardList = ArrayUtil.clone(receivementCardList);

                // Gera a lista de recebimentos da administradora de cartão com base no recebimento de cartão
                this._generateReceivementCardAdministratorList();
            }
        } catch (error) {
            this.loading = false;
            this.handleError(error);
        }
    }

    private _getNumberMonths(initial: Date, final: Date): number {
        const startDate: moment_.Moment = moment(initial).startOf('day'); // Inicia o dia em 00:00
        const endDate: moment_.Moment = moment(final).startOf('day'); // Inicia o dia em 00:00

        // Calcula a diferença em meses entre as duas datas
        const months: number = endDate.diff(startDate, 'months', true);

        // Arredonda o número de meses para o inteiro superior
        return Math.ceil(months);
    }

    private _generateReceivementCardAdministratorList(): void {
        this._receivementCardAdministratorList = [];

        let discountRate: number = 0;
        let originalValue: number = 0;
        let discountAnticipation: number = 0;

        for (const receivementCard of this._receivementCardList) {
            const receivementCardAdministrator: FinancialReceivementCardAdministrator = new FinancialReceivementCardAdministrator();
            receivementCardAdministrator.id = null;
            receivementCardAdministrator.enabled = true;
            receivementCardAdministrator.dateReceivement = new Date();
            receivementCardAdministrator.companyId = receivementCard.companyId;
            receivementCardAdministrator.receivementCard = ObjectUtil.clone(receivementCard);

            // Valor original
            receivementCardAdministrator.amountNet = receivementCard.amountNet;
            originalValue += receivementCardAdministrator.amountNet;

            // Valor da taxa
            receivementCardAdministrator.discountRate = (receivementCard.amountCard - receivementCard.amountNet);
            discountRate += receivementCardAdministrator.discountRate;

            // Valor da taxa antecipação
            receivementCardAdministrator.discountAnticipation = 0;
            if (this.data.anticipation) {
                const anticipationFee = (
                    (receivementCard.chargeType.type === 'CREDIT_CARD')
                        ? receivementCard.cardAdministrator.creditAnticipationFee
                        : receivementCard.cardAdministrator.debitAnticipationFee
                );

                const dueDate: Date = new Date(moment(receivementCard.dueDate).format('yyyy/MM/DD'));

                if (DateUtil.isLessThan(this.receivementDate, dueDate)) {
                    const months: number = this._getNumberMonths(this.receivementDate, dueDate);
                    receivementCardAdministrator.discountAnticipation = (receivementCardAdministrator.amountNet * ((anticipationFee * months) / 100));
                    discountAnticipation += receivementCardAdministrator.discountAnticipation;
                }

                receivementCardAdministrator.amountAnticipation = (receivementCardAdministrator.amountNet - receivementCardAdministrator.discountAnticipation);
            } else {
                discountAnticipation += receivementCardAdministrator.discountAnticipation;
            }

            this._receivementCardAdministratorList.push(receivementCardAdministrator);
        }

        this.receivementAmounts = {
            originalValue: originalValue,
            discountRate: discountRate,
            discountAnticipation: discountAnticipation,
            amountNet: (originalValue - (discountRate + discountAnticipation)),
        };
    }

    private _removeCostCenter(costCenterList: FinancialCostCenter[]): void {
        for (let i: number = 0; i < this.assessmentList.length; i++) {
            const billAssessment: FinancialBillAssessment = this.assessmentList[i];
            const costCenter: FinancialCostCenter = costCenterList.find(costCenter => costCenter.id === billAssessment.costCenter.id);
            if (!ObjectUtil.isNull(costCenter)) {
                this.costCenterExludList.push(costCenter);
            }
            costCenterList.sort(ArrayUtil.sortObjects([{ property: 'fullCode', isAsc: true }]));
        }
    }

    private _loadCostCenterAssessment(): void {
        this.assessmentList = [];

        if (!ObjectUtil.isNull(this.receivementPostingCategory.costCenterAssessmentId)) {
            this._costCenterAssessmentService.getOne(this.receivementPostingCategory.costCenterAssessmentId).toPromise().then(costCenterAssessment => {
                if (!ObjectUtil.isNull(costCenterAssessment)) {
                    for (const entity of costCenterAssessment.costCenterAssessmentCostCenterList) {
                        const assessment = new FinancialBillAssessment();
                        assessment.costCenter = entity.costCenter;
                        assessment.percentage = entity.percentage;

                        const amountNet: number = this.amountNetDiscount;
                        assessment.amount = (amountNet * (assessment.percentage / 100));

                        this.addAssessment(assessment);
                    }
                } else {
                    this.assessmentList = [];
                    this.assessment.percentage = (100 - this.totalPercentageAssessment);
                }
            }).catch(error => {
                this.addErrorMessage(error);
                this.loading = false;
            });
        }
    }

    private _validateAssessment(): boolean {
        if (ObjectUtil.isNull(this.assessment)) {
            return false;
        }

        if (ObjectUtil.isNull(this.assessment.costCenter)) {
            this.addWarningMessage('Campo centro de custo é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.percentage)) {
            this.addWarningMessage('Campo percentual é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.percentage)) {
            this.addWarningMessage('O percentual informado não pode ser superior à 100');
            return false;
        }
        if ((this.totalPercentageAssessment + this.assessment.percentage) > 100) {
            this.addWarningMessage('A soma dos percentuais não pode ser superior à 100');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.amount)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    public calculateAssessmentAmount(): void {
        const amountNet: number = this.amountNetDiscount;
        this.assessment.amount = (amountNet * (this.assessment.percentage / 100));
        this.assessmentList.forEach(assessment => assessment.amount = (amountNet * (assessment.percentage / 100)));
    }

    public addAssessment(entity?: FinancialBillAssessment): void {
        try {
            this.assessment = (!ObjectUtil.isNull(entity) ? entity : this.assessment);

            if (!this._validateAssessment()) {
                return;
            }

            const assessmentList: FinancialBillAssessment[] = [];
            assessmentList.push(...this.assessmentList, ObjectUtil.clone(this.assessment));
            this.assessmentList = assessmentList;

            this.costCenterExludList.push(this.assessment.costCenter);

            this.assessment = new FinancialBillAssessment();
            this.assessment.percentage = (100 - this.totalPercentageAssessment);
            this.calculateAssessmentAmount();
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public removeAssessment(entity: FinancialBillAssessment): void {
        try {
            const assessmentList: FinancialBillAssessment[] = [];

            const indexCostCenter: number = this.costCenterExludList.findIndex(cc => cc.id === entity.costCenter.id);
            if (indexCostCenter >= 0) {
                this.costCenterExludList.splice(indexCostCenter, 1);
            }

            assessmentList.push(...this.assessmentList);
            assessmentList.splice(assessmentList.indexOf(entity), 1);
            this.assessmentList = assessmentList;

            this.assessment.percentage = (100 - this.totalPercentageAssessment);
            this.calculateAssessmentAmount();
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public recalculateFees(): void {
        if (
            !ObjectUtil.isNull(this.receivementDate) &&
            DateUtil.isValid(new Date(this.receivementDate))
        ) {
            this._generateReceivementCardAdministratorList();
        }
    }

    public postingCategorySelectionChange(entity: FinancialPostingCategory): void {
        if (ObjectUtil.isNull(entity)) {
            return;
        }
        if (this.UUIDIsValid(this.receivementPostingCategory.costCenterAssessmentId)) {
            this._loadCostCenterAssessment();
        } else {
            this.assessmentList = [];
            this.assessment.percentage = (100 - this.totalPercentageAssessment);
        }
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.receivementDate) || !DateUtil.isValid(this.receivementDate)) {
                this.addWarningMessage('Campo data do recebimento é obrigatório e não foi informado');
                this.loading = false;
                return;
            }
            if (ObjectUtil.isNull(this.receivementPostingCategory)) {
                this.addWarningMessage('Campo categoria é obrigatório e não foi informado');
                this.loading = false;
                return;
            }

            this.loading = true;

            for (const receivementCardAdministrator of this._receivementCardAdministratorList) {
                receivementCardAdministrator.postCategory = new FinancialPostingCategory(this.receivementPostingCategory.id);
                receivementCardAdministrator.dateReceivement = moment(this.receivementDate).toDate();

                for (const assessment of this.assessmentList) {
                    const billAssessment = ObjectUtil.clone(assessment);
                    billAssessment.amount = Number.parseFloat((receivementCardAdministrator.amountNet * billAssessment.percentage / 100).toFixed(2));
                    receivementCardAdministrator.assessmentList.push(billAssessment);
                }
            }

            for (const assessment of this.assessmentList) {
                let amountTotal: number = 0;

                this._receivementCardAdministratorList.forEach((receivementCardAdministrator) => {
                    receivementCardAdministrator.assessmentList.forEach((assess) => {
                        if (assess.costCenter.fullCode === assessment.costCenter.fullCode) {
                            amountTotal = amountTotal + assess.amount;
                        }
                    });
                });

                if (amountTotal > assessment.amount) {
                    this._receivementCardAdministratorList[this._receivementCardAdministratorList.length - 1].assessmentList.forEach((assess) => {
                        if (assess.costCenter.fullCode === assessment.costCenter.fullCode) {
                            assess.amount = assess.amount - (amountTotal - assessment.amount);
                        }
                    });
                } else if (amountTotal < assessment.amount) {
                    this._receivementCardAdministratorList[this._receivementCardAdministratorList.length - 1].assessmentList.forEach((assess) => {
                        if (assess.costCenter.fullCode === assessment.costCenter.fullCode) {
                            assess.amount = assess.amount + (assessment.amount - amountTotal);
                        }
                    });
                }
            }

            this.service.receive(this._receivementCardAdministratorList).toPromise().then(_ => {
                this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
                this.close('RELOAD_TABLE')
            }).catch(error => {
                this.loading = false;
                this.handleError(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
