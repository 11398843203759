import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { StringUtil } from '@gipisistemas/ng-core';
import { FinancialCostCenterAssessment } from '../models/cost-center-assessment.model';
import { FinancialCostCenterAssessmentFilterDTO } from '../models/dto/cost-center-assessment.filter.dto';
@Injectable({ providedIn: 'root' })
export class FinancialCostCenterAssessmentsService extends BaseCrudService<FinancialCostCenterAssessment, FinancialCostCenterAssessmentFilterDTO> {

    constructor(
        http: HttpClient,
        authenticationService: CustomAuthenticationService
    ) {
        super(FinancialPaths.costCenterAssessment, http, authenticationService);
    }

    validate(entity: FinancialCostCenterAssessment): void {
        super.validate(entity);
        if (StringUtil.isEmpty(entity.description)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        const sum = +entity.costCenterAssessmentCostCenterList.reduce((a, b) => a + b.percentage, 0).toFixed(2);
        if (sum > 100) {
            throw new Error('A soma dos percentuais não pode ser maior que 100');
        }
        if (sum < 100) {
            throw new Error('A soma dos percentuais não pode ser menor que 100');
        }
    }

}
