import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { ReasonRefundService } from '@gipi-financial/reason-refund/services/reason-refund.service';
import { DateUtil, GIPIAbstractComponent, GIPIBaseService, ObjectUtil } from '@gipisistemas/ng-core';
export class MoveCheckReceivedDialogComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, _checkReceivedService, _bankAccountService, _reasonRefundService, dialogRef, data) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._checkReceivedService = _checkReceivedService;
        this._bankAccountService = _bankAccountService;
        this._reasonRefundService = _reasonRefundService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.reasonRefund = null;
        this.bankAccount = null;
        this.moveDate = new Date();
        this.reasonRefundFindByValueFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._reasonRefundService.findByValue(value, page, 20).toPromise();
            return result;
        });
        this.bankAccountFindByTypeFn = (value, page) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this._bankAccountService.findByValue(value, page, 20).toPromise();
            return result;
        });
    }
    ngOnInit() {
        super.ngOnInit();
    }
    setTitle() {
        switch (this.data.action) {
            case 'DEPOSIT_CHECK': return 'Depositar';
            case 'CUSTODY_CHECK': return 'Enviar para custódia';
            case 'COMPENSATE_DEPOSIT_CHECK': return 'Compensar';
            case 'REFUND1_CHECK': return 'Devolução';
            case 'REFUND2_CHECK': return 'Devolução';
        }
    }
    setLabelDate() {
        switch (this.data.action) {
            case 'DEPOSIT_CHECK': return 'Data do depósito';
            case 'CUSTODY_CHECK': return 'Data envio custódia';
            case 'COMPENSATE_DEPOSIT_CHECK': return 'Data da compensação';
            case 'REFUND1_CHECK': return 'Data da devolução';
            case 'REFUND2_CHECK': return 'Data da devolução';
        }
    }
    confirm() {
        try {
            if (ObjectUtil.isNull(this.moveDate) || !DateUtil.isValid(this.moveDate)) {
                const labelDate = this.setLabelDate().toLocaleLowerCase();
                this.addWarningMessage(`Campo ${labelDate} é obrigatório e não informado`);
                return;
            }
            if (ObjectUtil.isNull(this.bankAccount) && ((this.data.action === 'DEPOSIT_CHECK') || (this.data.action === 'CUSTODY_CHECK'))) {
                this.addWarningMessage('Campo conta bancária é obrigatório e não informado');
                return;
            }
            if (ObjectUtil.isNull(this.reasonRefund) && ((this.data.action === 'REFUND1_CHECK') || (this.data.action === 'REFUND2_CHECK'))) {
                this.addWarningMessage('Campo motivo da devolução é obrigatório e não informado');
                return;
            }
            this.loading = true;
            const reasonRefundId = ((this.data.action === 'REFUND1_CHECK') || (this.data.action === 'REFUND2_CHECK')) ? { id: this.reasonRefund.id } : null;
            const bankAccountId = ((this.data.action === 'DEPOSIT_CHECK') || (this.data.action === 'CUSTODY_CHECK')) ? { id: this.bankAccount.id } : null;
            const moveCheckReceivedList = this.data.checkReceivedList.map(checkReceived => ({
                id: checkReceived.id,
                check: {
                    moveDate: this.moveDate,
                    reasonRefund: reasonRefundId,
                },
                bankAccount: bankAccountId,
            }));
            this._checkReceivedService.moveCheckReceived(moveCheckReceivedList, this.data.action).toPromise().then(() => {
                this.close('RELOAD_TABLE');
            }, error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }
    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation) {
        this.dialogRef.close(operation);
    }
}
