import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBillAssessment } from '@gipi-financial/bill/models/bill-assessment.model';
import { FinancialCheckAction } from '@gipi-financial/check/enums/check-action.enum';
import { FinancialPostingCategorySelectDTO } from '@gipi-financial/posting-category/models/dto/posting-category-select.dto';
import { GIPIBaseAbstractModel } from '@gipi-shared/models/base-abstract.model';
import { FinancialCheckReceived } from './check-received.model';

export class FinancialCustody extends GIPIBaseAbstractModel {

    /** Ação de confirmação do cheque */
    action: FinancialCheckAction;

    /** Rateio do centro de custo */
    assessmentList: FinancialBillAssessment[];

    /** Conta bancária */
    bankAccount: FinancialBankAccount;

    /** Lista de cheques recebidos */
    checkReceivedList: FinancialCheckReceived[];

    /** Data de confirmação */
    confirmDate: Date;

    /** Valor da despesa */
    expenseAmount: number;

    /** Categoria */
    postingCategory: FinancialPostingCategorySelectDTO;

    /** Crédito total */
    totalCredit: number;

}
