import { Injectable } from '@angular/core';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { FinancialCustody } from '../models/custody.model';

@Injectable({ providedIn: 'root' })
export class FinancialCustodyService extends GIPIAbstractCrudService<FinancialCustody, GIPIBaseAbstractFilterModel> {

    constructor(protected baseService: GIPIBaseService,) {
        super(FinancialPaths.custody, 'v1', baseService);
    }

}
