import { Injectable } from '@angular/core';

import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { FinancialReasonRefundFilterDTO } from '../models/dto/reason-refund-filter.dto';
import { FinancialReasonRefund } from '../models/reason-refund.model';

@Injectable({ providedIn: 'root' })
export class ReasonRefundService extends GIPIAbstractCrudService<FinancialReasonRefund, FinancialReasonRefundFilterDTO> {

    constructor(protected baseService: GIPIBaseService,) {
        super(FinancialPaths.reasonRefund, 'v1', baseService);
    }

}
