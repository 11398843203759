import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialCheckReceivedService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.checkReceived, 'v1', baseService);
        this.baseService = baseService;
    }
    updateCheckReceivedList(checkReceivedList) {
        return this.baseService.httpClient.put(this.url('update-check-receiveds'), checkReceivedList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByIds(checkReceivedIdList) {
        return this.baseService.httpClient.post(this.url('find-by-ids'), checkReceivedIdList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    moveCheckReceived(moveCheckReceivedList, action) {
        return this.baseService.httpClient.post(this.url(`move-check/${action}`), moveCheckReceivedList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialCheckReceivedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialCheckReceivedService_Factory() { return new FinancialCheckReceivedService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialCheckReceivedService, providedIn: "root" });
