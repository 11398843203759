import { HttpClient } from '@angular/common/http';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { BaseCrudService } from '@gipi-shared/services/base-crud.service';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { StringUtil } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/custom-authentication.service";
export class FinancialCostCenterAssessmentsService extends BaseCrudService {
    constructor(http, authenticationService) {
        super(FinancialPaths.costCenterAssessment, http, authenticationService);
    }
    validate(entity) {
        super.validate(entity);
        if (StringUtil.isEmpty(entity.description)) {
            throw new Error('Campos obrigatórios (*) não informados');
        }
        const sum = +entity.costCenterAssessmentCostCenterList.reduce((a, b) => a + b.percentage, 0).toFixed(2);
        if (sum > 100) {
            throw new Error('A soma dos percentuais não pode ser maior que 100');
        }
        if (sum < 100) {
            throw new Error('A soma dos percentuais não pode ser menor que 100');
        }
    }
}
FinancialCostCenterAssessmentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialCostCenterAssessmentsService_Factory() { return new FinancialCostCenterAssessmentsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CustomAuthenticationService)); }, token: FinancialCostCenterAssessmentsService, providedIn: "root" });
