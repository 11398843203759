import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { FinancialBillAssessment } from '@gipi-financial/bill/models/bill-assessment.model';
import { FinancialCheckReceived } from '@gipi-financial/check-received/models/check-received.model';
import { FinancialCustody } from '@gipi-financial/check-received/models/custody.model';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { FinancialCustodyService } from '@gipi-financial/check-received/services/custody.service';
import { FinancialCostCenterAssessmentsService } from '@gipi-financial/cost-center-assessment/services/cost-center-assessments.service';
import { FinancialCostCenter } from '@gipi-financial/cost-center/models/cost-center.model';
import { FinancialCostCenterService } from '@gipi-financial/cost-center/services/cost-center.service';
import { FinancialPostingCategorySelectDTO } from '@gipi-financial/posting-category/models/dto/posting-category-select.dto';
import { FinancialPostingCategoryService } from '@gipi-financial/posting-category/services/posting-category.service';
import { ArrayUtil, CurrencyUtil, DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, GIPISortModel, GIPIUuid, NumberUtil, ObjectUtil, TableColumnBuilder, TableColumnDTO } from '@gipisistemas/ng-core';

export interface ConfirmCustodyCheckReceivedData {
    ids: GIPIUuid[];
}

@Component({
    templateUrl: './confirm-custody-check-received-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class ConfirmCustodyCheckReceivedDialogComponent extends GIPIAbstractComponent implements OnInit {

    private _checkReceivedList: FinancialCheckReceived[] = [];

    public entity: FinancialCustody = this._newEntity();

    public assessmentList: FinancialBillAssessment[] = [];
    public assessment: FinancialBillAssessment = new FinancialBillAssessment();

    public costCenterExludList: FinancialCostCenter[] = [];

    public costCenterColumns: TableColumnDTO[] = this._createTableColumnsCostCenter();

    postingCategoryFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialPostingCategorySelectDTO> = await this._postingCategoryService.findByValue<FinancialPostingCategorySelectDTO>(value, page, 10, new GIPISortModel('fullCode', 'ASC'), 'v1', 'DEBIT', true).toPromise();
        return result;
    };
    postingCategoryFn = (obj: FinancialPostingCategorySelectDTO) => `${obj.fullCode} - ${obj.description}`;

    costCenterFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialCostCenter> = await this._costCenterService.findByValue(value, page, 10, { property: 'fullCode', direction: 'asc' }, true).toPromise();
        return result;
    };
    costCenterFn = (obj: FinancialCostCenter) => `${obj.fullCode} - ${obj.description}`;

    public get totalPercentageAssessment(): number {
        return this.assessmentList.reduce((sum, e) => sum += e.percentage, 0);
    }

    constructor(
        protected service: FinancialCustodyService,
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _checkReceivedService: FinancialCheckReceivedService,
        private _costCenterService: FinancialCostCenterService,
        private _postingCategoryService: FinancialPostingCategoryService,
        private _costCenterAssessmentService: FinancialCostCenterAssessmentsService,
        public dialogRef: MatDialogRef<ConfirmCustodyCheckReceivedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmCustodyCheckReceivedData,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._findCheckReceiveds();
    }

    private _newEntity(): FinancialCustody {
        const entity: FinancialCustody = new FinancialCustody();
        entity.action = 'CONFIRM_CUSTODY_CHECK';
        entity.assessmentList = [];
        entity.bankAccount = null;
        entity.checkReceivedList = [];
        entity.confirmDate = new Date();
        entity.expenseAmount = 0;
        entity.postingCategory = null;
        entity.totalCredit = 0;
        return entity;
    }

    private async _findCheckReceiveds(): Promise<void> {
        try {
            if (
                ObjectUtil.isNull(this.data) ||
                (!ObjectUtil.isNull(this.data) && ArrayUtil.isEmpty(this.data.ids))
            ) {
                return;
            }

            this._checkReceivedList = await this._checkReceivedService.findByIds(this.data.ids).toPromise().catch(error => {
                this.loading = false;
                this.addErrorMessage(error);
                return [];
            });

            if (!ArrayUtil.isEmpty(this._checkReceivedList)) {
                this.entity.bankAccount = this._checkReceivedList[0].bankAccount;
                this.entity.totalCredit = this._checkReceivedList.reduce((sum, e) => sum += e.check.amount, 0);
            }
        } catch (error) {
            this.loading = false;
            this.handleError(error);
        }
    }

    private _createTableColumnsCostCenter(): TableColumnDTO[] {
        return [
            TableColumnBuilder.instance()
                .property('code')
                .name('Código')
                .align('center center')
                .width(10)
                .align('center center')
                .value((obj: FinancialBillAssessment) => obj.costCenter.fullCode)
                .build(),
            TableColumnBuilder.instance()
                .property('description')
                .name('Centro de custo')
                .value((obj: FinancialBillAssessment) => obj.costCenter.description)
                .build(),
            TableColumnBuilder.instance()
                .property('percentage')
                .name('Percentual')
                .value((obj: FinancialBillAssessment) => obj.percentage + '%')
                .width(10)
                .align('center center')
                .build(),
            TableColumnBuilder.instance()
                .property('amount')
                .name('Valor')
                .value((obj: FinancialBillAssessment) => CurrencyUtil.transform(obj.amount, '1.2-2'))
                .width(10)
                .align('center center')
                .build()
        ];
    }

    private _loadCostCenterAssessment(): void {
        this.assessmentList = [];

        if (!ObjectUtil.isNull(this.entity.postingCategory.costCenterAssessmentId)) {
            this._costCenterAssessmentService.getOne(this.entity.postingCategory.costCenterAssessmentId).toPromise().then(costCenterAssessment => {
                if (!ObjectUtil.isNull(costCenterAssessment)) {
                    for (const entity of costCenterAssessment.costCenterAssessmentCostCenterList) {
                        const assessment = new FinancialBillAssessment();
                        assessment.costCenter = entity.costCenter;
                        assessment.percentage = entity.percentage;
                        assessment.amount = (this.entity.expenseAmount * (assessment.percentage / 100));

                        this.addAssessment(assessment);
                    }
                } else {
                    this.assessmentList = [];
                    this.assessment.percentage = (100 - this.totalPercentageAssessment);
                }
            }).catch(error => {
                this.addErrorMessage(error);
                this.loading = false;
            });
        }
    }

    private _validateAssessment(): boolean {
        if (ObjectUtil.isNull(this.assessment)) {
            return false;
        }

        if (this.entity.assessmentList.some(e => e.costCenter.id === this.assessment.costCenter.id)) {
            this.addWarningMessage('O centro de custo selecionado já foi adicionado');
            return false;
        }
        if (ObjectUtil.isNull(this.assessment.costCenter)) {
            this.addWarningMessage('Campo centro de custo é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.percentage)) {
            this.addWarningMessage('Campo percentual é obrigatório e não foi informado');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.percentage)) {
            this.addWarningMessage('O percentual informado não pode ser superior à 100');
            return false;
        }
        if ((this.totalPercentageAssessment + this.assessment.percentage) > 100) {
            this.addWarningMessage('A soma dos percentuais não pode ser superior à 100');
            return false;
        }
        if (!NumberUtil.isPositive(this.assessment.amount)) {
            this.addWarningMessage('Campo valor é obrigatório e não foi informado');
            return false;
        }

        return true;
    }

    public calculateAssessmentAmount(): void {
        this.assessment.amount = (this.entity.expenseAmount * (this.assessment.percentage / 100));
        this.assessmentList.forEach(assessment => assessment.amount = (this.entity.expenseAmount * (assessment.percentage / 100)));
    }

    public addAssessment(entity?: FinancialBillAssessment): void {
        try {
            this.assessment = (!ObjectUtil.isNull(entity) ? entity : this.assessment);

            if (!this._validateAssessment()) {
                return;
            }

            const assessmentList: FinancialBillAssessment[] = [];
            assessmentList.push(...this.assessmentList, ObjectUtil.clone(this.assessment));
            this.assessmentList = assessmentList;

            this.costCenterExludList.push(this.assessment.costCenter);

            this.assessment = new FinancialBillAssessment();
            this.assessment.percentage = (100 - this.totalPercentageAssessment);
            this.calculateAssessmentAmount();
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public removeAssessment(entity: FinancialBillAssessment): void {
        try {
            const assessmentList: FinancialBillAssessment[] = [];

            const indexCostCenter: number = this.costCenterExludList.findIndex(cc => cc.id === entity.costCenter.id);
            if (indexCostCenter >= 0) {
                this.costCenterExludList.splice(indexCostCenter, 1);
            }

            assessmentList.push(...this.assessmentList);
            assessmentList.splice(assessmentList.indexOf(entity), 1);
            this.assessmentList = assessmentList;

            this.assessment.percentage = (100 - this.totalPercentageAssessment);
            this.calculateAssessmentAmount();
        } catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }

    public postingCategorySelectionChange(entity: FinancialPostingCategorySelectDTO): void {
        if (ObjectUtil.isNull(entity)) {
            return;
        }
        if (this.UUIDIsValid(this.entity.postingCategory.costCenterAssessmentId)) {
            this._loadCostCenterAssessment();
        } else {
            this.assessmentList = [];
            this.assessment.percentage = (100 - this.totalPercentageAssessment);
        }
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.entity.confirmDate) || !DateUtil.isValid(this.entity.confirmDate)) {
                this.addWarningMessage('Campo data da confirmação é obrigatório e não informado');
                return;
            }
            if (ObjectUtil.isNull(this.entity.bankAccount)) {
                this.addWarningMessage('Campo conta bancária é obrigatório e não informado');
                return;
            }
            if (ObjectUtil.isNull(this.entity.postingCategory)) {
                this.addWarningMessage('Campo cateegoria é obrigatório e não informado');
                return;
            }
            if (ArrayUtil.isEmpty(this._checkReceivedList)) {
                this.addWarningMessage('Os cheques não foram consultados portanto não é possível confirmar a custódia');
                return;
            }

            this.loading = true;

            this.entity.checkReceivedList = ArrayUtil.clone(this._checkReceivedList);
            this.entity.checkReceivedList.forEach(e => {
                e.check.status = 'CUSTODIAN';
                e.check.moveDate = this.entity.confirmDate;
            });

            this.entity.assessmentList = ArrayUtil.clone(this.assessmentList);

            this.service.save(this.entity).toPromise().then(() => {
                this.close('RELOAD_TABLE');
            }, error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    public close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }


}
