import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { FinancialCheckAction } from '@gipi-financial/check/enums/check-action.enum';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIUuid } from '@gipisistemas/ng-core';
import { FinancialCheckReceived } from '../models/check-received.model';
import { FinancialCheckReceivedFilterDTO } from '../models/dto/check-received-filter.dto';
import { FinancialMoveCheckReceivedDTO } from '../models/dto/move-check-received.dto';

@Injectable({ providedIn: 'root' })
export class FinancialCheckReceivedService extends GIPIAbstractCrudService<FinancialCheckReceived, FinancialCheckReceivedFilterDTO> {

    constructor(protected baseService: GIPIBaseService) {
        super(FinancialPaths.checkReceived, 'v1', baseService);
    }

    updateCheckReceivedList(checkReceivedList: FinancialCheckReceived[]): Observable<FinancialCheckReceived[]> {
        return this.baseService.httpClient.put(this.url('update-check-receiveds'), checkReceivedList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    findByIds(checkReceivedIdList: GIPIUuid[]): Observable<FinancialCheckReceived[]> {
        return this.baseService.httpClient.post(this.url('find-by-ids'), checkReceivedIdList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

    moveCheckReceived<T = FinancialMoveCheckReceivedDTO>(moveCheckReceivedList: T[], action: FinancialCheckAction): Observable<FinancialCheckReceived[]> {
        return this.baseService.httpClient.post(this.url(`move-check/${action}`), moveCheckReceivedList, this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError)
        );
    }

}
