import { catchError, map, takeUntil } from 'rxjs/operators';
import * as moment_ from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
const moment = moment_;
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { ArrayUtil, DateUtil, GIPIAbstractCrudService, GIPIBaseService, ObjectUtil, UUIDUtil } from '@gipisistemas/ng-core';
export class FinancialReceivementCardService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.receivementCard, 'v1', baseService);
        this.baseService = baseService;
    }
    findByIds(idList, version) {
        return this.baseService.httpClient.post(this.url('find-by-ids', version), idList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findByBillInstallment(id, version) {
        return this.baseService.httpClient.get(this.url(`find-by-installment/${id}`, version), this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    cancel(receivementCardIdList, version) {
        for (const uuid of receivementCardIdList) {
            if (!UUIDUtil.isValid(uuid)) {
                throw new Error('Os recebimentos de cartão informados não são válidos');
            }
        }
        return this.baseService.httpClient.post(this.url(`cancel`, version), receivementCardIdList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    receive(entityList, version) {
        return this.baseService.httpClient.post(this.url(`receive`, version), entityList, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
    findAll(filter, version) {
        return this.baseService.httpClient.post(this.url('find-all', version), filter, this.options()).pipe(map(json => {
            const page = this.handleMapper(json);
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                const date = new Date();
                const compareDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
                for (let i = 0; i < page.content.length; i++) {
                    const dueDate = new Date(moment(page.content[i].dueDate).format('yyyy/MM/DD'));
                    const isLessThan = DateUtil.isLessThan(new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate()), new Date(compareDate.getFullYear(), compareDate.getMonth(), compareDate.getDate()));
                    if (isLessThan && page.content[i].status === 'RECEIVABLE') {
                        page.content[i].status = 'OVERDUE';
                    }
                }
            }
            return page;
        }), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialReceivementCardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialReceivementCardService_Factory() { return new FinancialReceivementCardService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialReceivementCardService, providedIn: "root" });
