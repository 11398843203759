import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { FinancialBankAccount } from '@gipi-financial/bank-account/models/bank-account.model';
import { FinancialBankAccountService } from '@gipi-financial/bank-account/services/bank-account.service';
import { FinancialCheckReceivedConsultDTO } from '@gipi-financial/check-received/models/dto/check-received-consult.dto';
import { FinancialMoveCheckReceivedDTO } from '@gipi-financial/check-received/models/dto/move-check-received.dto';
import { FinancialCheckReceivedService } from '@gipi-financial/check-received/services/check-received.service';
import { FinancialReasonRefund } from '@gipi-financial/reason-refund/models/reason-refund.model';
import { ReasonRefundService } from '@gipi-financial/reason-refund/services/reason-refund.service';
import { DateUtil, GIPIAbstractComponent, GIPIBaseService, GIPIPageModel, ObjectUtil } from '@gipisistemas/ng-core';

export interface MoveCheckReceivedData {
    checkReceivedList: FinancialCheckReceivedConsultDTO[];
    action: ('DEPOSIT_CHECK' | 'CUSTODY_CHECK' | 'COMPENSATE_DEPOSIT_CHECK' | 'REFUND1_CHECK' | 'REFUND2_CHECK');
}

@Component({
    templateUrl: './move-check-received-dialog.component.html',
    styles: [`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }`]
})
export class MoveCheckReceivedDialogComponent extends GIPIAbstractComponent implements OnInit {

    public reasonRefund: FinancialReasonRefund = null;

    public bankAccount: FinancialBankAccount = null;

    public moveDate: Date = new Date();

    reasonRefundFindByValueFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialReasonRefund> = await this._reasonRefundService.findByValue(value, page, 20).toPromise();
        return result;
    };

    bankAccountFindByTypeFn = async (value: string, page: number) => {
        const result: GIPIPageModel<FinancialBankAccount> = await this._bankAccountService.findByValue(value, page, 20).toPromise();
        return result;
    };

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        private _checkReceivedService: FinancialCheckReceivedService,
        private _bankAccountService: FinancialBankAccountService,
        private _reasonRefundService: ReasonRefundService,
        public dialogRef: MatDialogRef<MoveCheckReceivedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MoveCheckReceivedData,
    ) {
        super(baseService, activatedRoute);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    public setTitle(): string {
        switch (this.data.action) {
            case 'DEPOSIT_CHECK': return 'Depositar';
            case 'CUSTODY_CHECK': return 'Enviar para custódia';
            case 'COMPENSATE_DEPOSIT_CHECK': return 'Compensar';
            case 'REFUND1_CHECK': return 'Devolução';
            case 'REFUND2_CHECK': return 'Devolução';
        }
    }

    public setLabelDate(): string {
        switch (this.data.action) {
            case 'DEPOSIT_CHECK': return 'Data do depósito';
            case 'CUSTODY_CHECK': return 'Data envio custódia';
            case 'COMPENSATE_DEPOSIT_CHECK': return 'Data da compensação';
            case 'REFUND1_CHECK': return 'Data da devolução';
            case 'REFUND2_CHECK': return 'Data da devolução';
        }
    }

    public confirm(): void {
        try {
            if (ObjectUtil.isNull(this.moveDate) || !DateUtil.isValid(this.moveDate)) {
                const labelDate: string = this.setLabelDate().toLocaleLowerCase();
                this.addWarningMessage(`Campo ${labelDate} é obrigatório e não informado`);
                return;
            }
            if (ObjectUtil.isNull(this.bankAccount) && ((this.data.action === 'DEPOSIT_CHECK') || (this.data.action === 'CUSTODY_CHECK'))) {
                this.addWarningMessage('Campo conta bancária é obrigatório e não informado');
                return;
            }
            if (ObjectUtil.isNull(this.reasonRefund) && ((this.data.action === 'REFUND1_CHECK') || (this.data.action === 'REFUND2_CHECK'))) {
                this.addWarningMessage('Campo motivo da devolução é obrigatório e não informado');
                return;
            }

            this.loading = true;

            const reasonRefundId = ((this.data.action === 'REFUND1_CHECK') || (this.data.action === 'REFUND2_CHECK')) ? { id: this.reasonRefund.id } : null;
            const bankAccountId = ((this.data.action === 'DEPOSIT_CHECK') || (this.data.action === 'CUSTODY_CHECK')) ? { id: this.bankAccount.id } : null;

            const moveCheckReceivedList: FinancialMoveCheckReceivedDTO[] = this.data.checkReceivedList.map(checkReceived => ({
                id: checkReceived.id,
                check: {
                    moveDate: this.moveDate,
                    reasonRefund: reasonRefundId,
                },
                bankAccount: bankAccountId,
            }));

            this._checkReceivedService.moveCheckReceived(moveCheckReceivedList, this.data.action).toPromise().then(() => {
                this.close('RELOAD_TABLE');
            }, error => {
                this.loading = false;
                this.addErrorMessage(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    /**
     * @template RELOAD_TABLE Dá reload na grid atualizando os registros
     * @template REMARK_SELECTED Volta a tela anterior e seleciona os registros na grid
     * @template NONE Não acontece nada, só volta para tela anterior
     */
    close(operation: 'RELOAD_TABLE' | 'REMARK_SELECTED' | 'NONE'): void {
        this.dialogRef.close(operation);
    }

}
