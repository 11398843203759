import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class ReasonRefundService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.reasonRefund, 'v1', baseService);
        this.baseService = baseService;
    }
}
ReasonRefundService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReasonRefundService_Factory() { return new ReasonRefundService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: ReasonRefundService, providedIn: "root" });
