import { ChangeDetectorRef, Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import * as moment_ from 'moment';
const moment = moment_;

import { RegistrationBenefitClub } from '@gipi-registration/benefit-club/models/benefit-club.model';
import { RegistrationBenefitClubService } from '@gipi-registration/benefit-club/services/benefit-club.service';
import { RegistrationClientClassification } from '@gipi-registration/client-classification/models/client-classification.model';
import { RegistrationClientClassificationService } from '@gipi-registration/client-classification/services/client-classification.service';
import { RegistrationClient } from '@gipi-registration/client/models/client.model';
import { RegistrationClientService } from '@gipi-registration/client/services/client.service';
import { RegistrationConnectConfig } from '@gipi-registration/connect-config/models/connect-config.model';
import { RegistrationConnectConfigService } from '@gipi-registration/connect-config/services/connect-config.service';
import { ConnectConsultData, ConnectConsultDialogComponent } from '@gipi-registration/connect-consult/components/connect-consult-dialog/connect-consult-dialog.component';
import { GIPIClientSheetCnpjDialogComponent } from '@gipi-registration/consult-cnpj/components/client-sheet-cnpj-dialog/client-sheet-cnpj-dialog.component';
import { RegistrationConsultCnpjService } from '@gipi-registration/consult-cnpj/services/consult-cnpj.service';
import { RegistrationLineOfBusiness } from '@gipi-registration/line-of-business/models/line-of-business.model';
import { RegistrationLineOfBusinessService } from '@gipi-registration/line-of-business/services/line-of-business.service';
import { RegistrationLegalPersonTypeEnum } from '@gipi-registration/person/enums/legal-person-type.enum';
import { RegistrationAddress } from '@gipi-registration/person/models/address.model';
import { RegistrationPersonConsultDTO } from '@gipi-registration/person/models/dto/person-consult.dto';
import { RegistrationLegalPerson } from '@gipi-registration/person/models/legal-person.model';
import { RegistrationNaturalPerson } from '@gipi-registration/person/models/natural-person.model';
import { RegistrationPerson } from '@gipi-registration/person/models/person.model';
import { RegistrationProvider } from '@gipi-registration/provider/models/provider.model';
import { RegistrationProviderService } from '@gipi-registration/provider/services/provider.service';
import { OptionYesNoEnum } from '@gipi-shared/enums/options-yes-no.enum';
import { TypePerson, TypePersonEnum } from '@gipi-shared/enums/type-person.enum';
import { APP_MESSAGES, ArrayUtil, DocumentUtil, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, GIPIUuid, INJECTOR, ObjectUtil, PageDTO, StringUtil } from '@gipisistemas/ng-core';
import { PersonAssociationData, PersonAssociationDialogComponent, PersonAssociationReturn } from '../person-association-dialog/person-association-dialog.component';
import { PersonListData, PersonListDialogComponent } from '../person-list-dialog/person-list-dialog.component';

@Component({
    selector: 'itss-person-form',
    templateUrl: './person-form.component.html',
    styleUrls: ['./person-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PersonFormComponent),
            multi: true
        }
    ],
})
export class PersonFormComponent extends GIPIAbstractComponent implements OnInit {

    @ContentChild('specificComponents', { static: false }) specificComponentsRef: TemplateRef<any>;

    typePersonEnum: typeof TypePersonEnum = TypePersonEnum;

    legalPersonTypeEnum: typeof RegistrationLegalPersonTypeEnum = RegistrationLegalPersonTypeEnum;

    optionYesNoEnum: typeof OptionYesNoEnum = OptionYesNoEnum;

    minDate: Date = new Date(1900, 0, 1, 0, 0, 0);

    private _connectConfigEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private _personConsultedByCnpj: RegistrationPerson;

    private _clientList: RegistrationClient[] = [];

    private _providerList: RegistrationProvider[] = [];

    /** Mudou o lineOfBusiness para a entidade da empresa */
    public _lineOfBusinessList: RegistrationLineOfBusiness[] = [];

    public _optionsBenefitClubList: RegistrationBenefitClub[] = [];

    @Input() typeRegister: 'CLIENT' | 'COMPANY' | 'PROVIDER' | 'REPRESENTATIVE_PROVIDER' | 'ACCOUNTING';

    @Input() disabled: boolean = false;

    private _ruralProducer: boolean = false;
    @Input() get ruralProducer(): boolean {
        return this._ruralProducer;
    }
    set ruralProducer(value: boolean) {
        this._ruralProducer = value;
        this.ruralProducerChange.emit(value);
    }

    private _icmsTaxPayer: boolean = false;
    @Input() get icmsTaxPayer(): boolean {
        return this._icmsTaxPayer;
    }
    set icmsTaxPayer(value: boolean) {
        this._icmsTaxPayer = value;
        this.icmsTaxPayerChange.emit(value);
    }

    private _personBlocked: OptionYesNoEnum | string = 'NO';
    @Input() get personBlocked(): OptionYesNoEnum | string {
        return this._personBlocked;
    }
    set personBlocked(value: OptionYesNoEnum | string) {
        this._personBlocked = value;
        this.personBlockedChange.emit(value);
    }

    private _controlClientLimit: OptionYesNoEnum | string = 'NO';
    @Input() get controlClientLimit(): OptionYesNoEnum | string {
        return this._controlClientLimit;
    }
    set controlClientLimit(value: OptionYesNoEnum | string) {
        this._controlClientLimit = value;
        this.controlClientLimitChange.emit(value);
    }

    private _personEnabled: OptionYesNoEnum | string = 'YES';
    @Input() get personEnabled(): OptionYesNoEnum | string {
        return this._personEnabled;
    }
    set personEnabled(value: OptionYesNoEnum | string) {
        this._personEnabled = value;
        this.personEnabledChange.emit(value);
    }

    private _commentsClient: string = '';
    @Input() get commentsClient(): string {
        return this._commentsClient;
    }
    set commentsClient(value: string) {
        this._commentsClient = value;
        this.commentsClientChange.emit(value);
    }

    private _personValue: RegistrationPerson = new RegistrationPerson();
    @Input() get person(): RegistrationPerson {
        return this._personValue;
    }
    set person(person: RegistrationPerson) {
        this._personValue = person;

        if (!ObjectUtil.isNull(this._personValue)) {
            if (!ObjectUtil.isNull(this._personValue.birthDate)) {
                this._personValue.birthDate = moment(this._personValue.birthDate).toDate();
            }
        }

        if (!ObjectUtil.isNull(this._personValue.legalPerson)) {
            this._personValue.typePerson = 'LEGAL_PERSON';
        } else {
            this._personValue.typePerson = 'NATURAL_PERSON';
        }

        this.personChange.emit(this._personValue);
    }

    entityValue: any;
    @Input() get entity(): any {
        return this.entityValue;
    }
    set entity(entity: any) {
        this.entityValue = entity;
        this.entityChange.emit(this.entityValue);
    }

    private _benefitClubList: RegistrationBenefitClub[];
    @Input() get benefitClubList(): RegistrationBenefitClub[] {
        return this._benefitClubList;
    }
    set benefitClubList(value: RegistrationBenefitClub[]) {
        this._benefitClubList = value;
        this.benefitClubListChange.emit(this.benefitClubList);
    }

    private _clientClassification: RegistrationClientClassification;
    @Input() get clientClassification(): RegistrationClientClassification {
        return this._clientClassification;
    }
    set clientClassification(value: RegistrationClientClassification) {
        this._clientClassification = value;
        this.clientClassificationChange.emit(this.clientClassification);
    }

    @Output() ruralProducerChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() icmsTaxPayerChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() personBlockedChange: EventEmitter<OptionYesNoEnum | string> = new EventEmitter<OptionYesNoEnum | string>();
    @Output() controlClientLimitChange: EventEmitter<OptionYesNoEnum | string> = new EventEmitter<OptionYesNoEnum | string>();
    @Output() personEnabledChange: EventEmitter<OptionYesNoEnum | string> = new EventEmitter<OptionYesNoEnum | string>();
    @Output() commentsClientChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() personChange: EventEmitter<RegistrationPerson> = new EventEmitter<RegistrationPerson>();
    @Output() entityChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() benefitClubListChange: EventEmitter<RegistrationBenefitClub[]> = new EventEmitter();
    @Output() clientClassificationChange: EventEmitter<RegistrationClientClassification> = new EventEmitter();
    @Output() findCnpjOrCpfChange: EventEmitter<void> = new EventEmitter<void>();

    clientClassificationFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<RegistrationClientClassification> = await this._clientClassificationService.findByValue<RegistrationClientClassification>(value, page, 10, new GIPISortModel('description', 'ASC')).toPromise();
        return result;
    };

    benefitClubFindByValueFn = async (value: string, page: number) => {
        const result: PageDTO<RegistrationBenefitClub> = await this._benefitClubService.findByValue<RegistrationBenefitClub>(value, page, 10, new GIPISortModel('description', 'ASC')).toPromise();
        return result;
    };

    constructor(
        protected baseService: GIPIBaseService,
        protected activatedRoute: ActivatedRoute,
        protected changeDetectorRef: ChangeDetectorRef,
        protected router: Router,
        private _clientService: RegistrationClientService,
        private _providerService: RegistrationProviderService,
        private _lineOfBusinessService: RegistrationLineOfBusinessService,
        private _consultCnpjService: RegistrationConsultCnpjService,
        private _clientClassificationService: RegistrationClientClassificationService,
        private _benefitClubService: RegistrationBenefitClubService,
        private _connectConfigService: RegistrationConnectConfigService,
    ) {
        super(baseService, activatedRoute);

        setTimeout(() => {
            if (ObjectUtil.isNull(this.person)) {
                this.person = new RegistrationPerson();
            }
            if (ObjectUtil.isNull(this.person.legalPerson)) {
                this.person.legalPerson = new RegistrationLegalPerson();

                // Mudou o lineOfBusiness para a entidade da empresa
                // if (this.typeRegister === 'COMPANY') {
                //     this.person.legalPerson.lineOfBusiness = new RegistrationLineOfBusiness();
                // }
            }
            if (ObjectUtil.isNull(this.person.naturalPerson)) {
                this.person.naturalPerson = new RegistrationNaturalPerson();
            }
        });
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        // Mudou o lineOfBusiness para a entidade da empresa
        // if (this.typeRegister === 'COMPANY') {
        //     this._findLineOfBusiness();
        // } else
        if (this.typeRegister === 'CLIENT') {
            this._findConnectConfig();

            this._connectConfigService.updated$.subscribe(isUpdated => {
                if (isUpdated) {
                    this._findConnectConfig();
                }
            });
        }
    }

    private _findLineOfBusiness(): void {
        try {
            // Mudou o lineOfBusiness para a entidade da empresa
            this._lineOfBusinessList = [];

            // this._lineOfBusinessService.findAllEnabled(0, 50).toPromise().then(page => {
            //     if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
            //     }
            // }, error => {
            //     throw new Error(error);
            // });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private _findConnectConfig(): void {
        try {
            if (ObjectUtil.isNull(this._connectConfigEnabled$)) {
                this._connectConfigEnabled$ = new BehaviorSubject(false);
            }

            this._connectConfigService.findAllEnabled<RegistrationConnectConfig>(0, 50).toPromise().then(page => {
                if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                    this._connectConfigEnabled$.next(page.content[0].enabled);
                }
            }, error => {
                throw new Error(error);
            });
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private async _showDialogPersonList(entityList: RegistrationPersonConsultDTO[]): Promise<GIPIUuid> {
        try {
            const personListData: PersonListData = {
                entityList: [...entityList],
                titleDialog: (this.typeRegister === 'CLIENT') ? 'Selecione um cliente' : 'Selecione um fornecedor',
            };

            const personSelected: RegistrationPersonConsultDTO = await this.baseService.dialogService.open({
                componentOrTemplateRef: PersonListDialogComponent,
                data: personListData,
                width: '60%',
                height: '63%'
            }).afterClosed().toPromise();

            if (ObjectUtil.isNull(personSelected)) {
                const resolved: boolean = await this._verifyCreateOrUpdateRegister();
                if (resolved) {
                    return;
                }
            }

            return Promise.resolve(personSelected.id);
        } catch (e) {
            this.loading = false;
            this.handleError(e);
            return Promise.resolve(null);
        }
    }

    private async _showDialogPersonAssociation(): Promise<PersonAssociationReturn> {
        let typeDocument: string = 'documento';
        if (!ObjectUtil.isNull(this.person)) {
            if (this.person.typePerson === 'NATURAL_PERSON') {
                typeDocument = 'CPF';
            } else if (this.person.typePerson === 'LEGAL_PERSON') {
                typeDocument = 'CNPJ';
            }
        }

        const personAssociationData: PersonAssociationData = {
            title: (this.typeRegister === 'CLIENT') ? 'Clientes associados' : 'Fornecedores associados',
            message: `Identificamos que existem registros associados a este ${typeDocument}. Deseja atualizar as informações desses registros ou criar um novo?`,
        };

        const typeOperation: PersonAssociationReturn = await this.baseService.dialogService.open({
            componentOrTemplateRef: PersonAssociationDialogComponent,
            data: personAssociationData,
            width: '20%',
        }).afterClosed().toPromise();

        return typeOperation;
    }

    private _redirectRegisterToEdit(id: GIPIUuid, permission: string): void {
        if (StringUtil.isEmpty(permission) || !this.hasPermission(permission)) {
            this.addWarningMessage(INJECTOR.get(APP_MESSAGES).NOT_PERMISSION);
            return;
        }
        if (!this.UUIDIsValid(id)) {
            return;
        }

        const pathEdit: string = (this.typeRegister === 'CLIENT') ? 'financial/registers/clients/clients' : 'financial/registers/providers';
        this.router.navigateByUrl(pathEdit, { skipLocationChange: true }).then(() => this.router.navigate([pathEdit, 'edit', id]));
    }

    private async _verifyCreateOrUpdateRegister(): Promise<boolean> {
        try {
            const entityList: (RegistrationClient | RegistrationProvider)[] = (this.typeRegister === 'CLIENT') ? [...this._clientList] : [...this._providerList];
            if (ArrayUtil.isEmpty(entityList)) {
                return Promise.resolve(false);
            }

            const typeOperation: PersonAssociationReturn = await this._showDialogPersonAssociation();
            // Se clicou em novo, ele volta e consulta pela receita, parando essa função aqui mesmo.
            if (typeOperation === 'NEW_RECORD') {
                return Promise.resolve(false);
            } else if (typeOperation === 'NONE') {
                // Se retornou 'NONE' é porque clicou em fechar o modal, então retorna true como se fosse resolvido para que o processo de consulta do
                // CNPJ se encerre.
                return Promise.resolve(true);
            }

            const permissionUpdate: 'CLIENT_UPDATE' | 'PROVIDER_UPDATE' = (this.typeRegister === 'CLIENT') ? 'CLIENT_UPDATE' : 'PROVIDER_UPDATE';
            if (entityList.length > 1) {
                const personList: RegistrationPersonConsultDTO[] = entityList.map(e => RegistrationPersonConsultDTO.toPersonDTO(e));
                const idSelected: GIPIUuid = await this._showDialogPersonList(personList);
                this._redirectRegisterToEdit(idSelected, permissionUpdate);
            } else {
                this._redirectRegisterToEdit(entityList[0].id, permissionUpdate);
            }

            return Promise.resolve(true);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private async _handleRegister(): Promise<boolean> {
        try {
            if (ObjectUtil.isNull(this.person)) {
                return Promise.resolve(false);
            }
            if ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER')) {
                return Promise.resolve(false);
            }

            const cpfOrCnpj: string = (this.person.typePerson === 'NATURAL_PERSON') ? this.person.naturalPerson.cpf : this.person.legalPerson.cnpj;
            if (StringUtil.isEmpty(cpfOrCnpj)) {
                return Promise.resolve(false);
            }

            const entityList: (RegistrationClient | RegistrationProvider)[] = ((this.typeRegister === 'CLIENT')
                ? await this._clientService.findByCpfOrCnpj(cpfOrCnpj).toPromise()
                : await this._providerService.findByCpfOrCnpj(cpfOrCnpj).toPromise()
            );

            this._clientList = [];
            this._providerList = [];
            if (!ArrayUtil.isEmpty(entityList)) {
                if (this.typeRegister === 'CLIENT') {
                    this._clientList = ArrayUtil.clone<RegistrationClient>((entityList as RegistrationClient[]));
                } else if (this.typeRegister === 'PROVIDER') {
                    this._providerList = ArrayUtil.clone<RegistrationProvider>((entityList as RegistrationProvider[]));
                }

                return this._verifyCreateOrUpdateRegister();
            }

            return Promise.resolve(false);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async consultCnpj(isBlur: boolean = false): Promise<void> {
        try {
            if (
                (isBlur && StringUtil.isEmpty(this.person.legalPerson.cnpj)) ||
                this.disabled ||
                this.loading ||
                this.isEditing() ||
                this.isViewing() ||
                !this.hasPermissionForOperation() ||
                ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER') && (this.typeRegister !== 'REPRESENTATIVE_PROVIDER'))
            ) {
                return;
            }
            if (!isBlur && StringUtil.isEmpty(this.person.legalPerson.cnpj)) {
                this.addWarningMessage('Preencha o campo para realizar a consulta');
                return;
            }
            if (!DocumentUtil.isValidCnpj(this.person.legalPerson.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return;
            }

            this.loading = true;

            if ((this.typeRegister === 'CLIENT') || (this.typeRegister === 'PROVIDER')) {
                const registerValidated: boolean = await this._handleRegister().catch(() => this.loading = false);
                // Se foi validado é porque, foi escolhido um cliente ou fornecedor pra ser atualizado, então para a função aqui.
                if (registerValidated) {
                    this.loading = false;
                    this.findCnpjOrCpfChange.emit();
                    return;
                }
            }

            await this._setPersonConsultedByCnpjInPerson();
        } catch (e) {
            this.handleError(e);
        }
    }

    public async consultCpf(): Promise<void> {
        try {
            if (StringUtil.isEmpty(this.person.naturalPerson.cpf)) {
                return;
            }
            if (
                this.disabled ||
                this.loading ||
                this.isEditing() ||
                this.isViewing() ||
                StringUtil.isEmpty(this.person.naturalPerson.cpf) ||
                !this.hasPermissionForOperation() ||
                ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER'))
            ) {
                this.addErrorMessage('Ocorreu um erro ao consulta o CPF');
                return;
            }
            if (!DocumentUtil.isValidCpf(this.person.naturalPerson.cpf)) {
                this.addWarningMessage('CPF informado é inválido');
                return;
            }

            this.loading = true;

            if ((this.typeRegister === 'CLIENT') || (this.typeRegister === 'PROVIDER')) {
                const registerValidated: boolean = await this._handleRegister().catch(() => this.loading = false);
                // Se foi validado é porque, foi escolhido um cliente ou fornecedor pra ser atualizado, então para a função aqui.
                if (registerValidated) {
                    this.loading = false;
                    this.findCnpjOrCpfChange.emit();
                    return;
                } else {
                    this.loading = false;
                    this.findCnpjOrCpfChange.emit();
                }

            }
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    private async _setPersonConsultedByCnpjInPerson(): Promise<boolean> {
        try {
            this.loading = true;

            this._personConsultedByCnpj = await this._consultCnpjService.consultCnpj(this.person.legalPerson.cnpj).toPromise();
            if (ObjectUtil.isNull(this._personConsultedByCnpj)) {
                this.addWarningMessage('Nenhum registro foi encontrado');
                this.loading = false;
                return Promise.resolve(false);
            }

            this.person.name = !StringUtil.isEmpty(this._personConsultedByCnpj.name) ? this._personConsultedByCnpj.name : '';
            this.person.fantasyName = !StringUtil.isEmpty(this._personConsultedByCnpj.fantasyName) ? this._personConsultedByCnpj.fantasyName : this._personConsultedByCnpj.name;
            this.person.birthDate = this._personConsultedByCnpj.birthDate || null;

            if (ObjectUtil.isNull(this.person.legalPerson)) {
                this.person.legalPerson = new RegistrationLegalPerson();
            }
            this.person.legalPerson.enabled = this._personConsultedByCnpj.legalPerson.enabled || true;
            this.person.legalPerson.cnae = this._personConsultedByCnpj.legalPerson.cnae || '';
            this.person.legalPerson.stateRegistration = this._personConsultedByCnpj.legalPerson.stateRegistration || '';
            this.person.legalPerson.municipalRegistration = this._personConsultedByCnpj.legalPerson.municipalRegistration || '';
            this.person.legalPerson.type = this._personConsultedByCnpj.legalPerson.type || null;

            if (!ArrayUtil.isEmpty(this._personConsultedByCnpj.addressList)) {
                if (!ArrayUtil.isEmpty(this.person.addressList)) {
                    this.person.addressList.filter(a => a.useInInvoice).map(addressDefault => {
                        addressDefault.enabled = this._personConsultedByCnpj.addressList[0].enabled || true;
                        addressDefault.city = this._personConsultedByCnpj.addressList[0].city || null;
                        addressDefault.cityList = [];

                        if (!ObjectUtil.isNull(this._personConsultedByCnpj.addressList[0].city)) {
                            addressDefault.state = this._personConsultedByCnpj.addressList[0].city.state || null;

                            if (!ObjectUtil.isNull(this._personConsultedByCnpj.addressList[0].city.state)) {
                                addressDefault.country = this._personConsultedByCnpj.addressList[0].city.state.country || null;
                            }

                            addressDefault.cityList = [this._personConsultedByCnpj.addressList[0].city];
                        }

                        addressDefault.complement = this._personConsultedByCnpj.addressList[0].complement || '';;
                        addressDefault.neighborhood = this._personConsultedByCnpj.addressList[0].neighborhood || '';;
                        addressDefault.street = this._personConsultedByCnpj.addressList[0].street || '';;
                        addressDefault.streetNumber = this._personConsultedByCnpj.addressList[0].streetNumber || '';;
                        addressDefault.useInInvoice = this._personConsultedByCnpj.addressList[0].useInInvoice || true;
                        addressDefault.zipCode = this._personConsultedByCnpj.addressList[0].zipCode || '';;
                        addressDefault.type = 'RESIDENTIAL';
                    });
                } else {
                    const addressConsulted: RegistrationAddress = new RegistrationAddress();
                    addressConsulted.enabled = this._personConsultedByCnpj.addressList[0].enabled || true;
                    addressConsulted.city = this._personConsultedByCnpj.addressList[0].city || null;
                    addressConsulted.cityList = [];

                    if (!ObjectUtil.isNull(this._personConsultedByCnpj.addressList[0].city)) {
                        addressConsulted.state = this._personConsultedByCnpj.addressList[0].city.state || null;

                        if (!ObjectUtil.isNull(this._personConsultedByCnpj.addressList[0].city.state)) {
                            addressConsulted.country = this._personConsultedByCnpj.addressList[0].city.state.country || null;
                        }

                        addressConsulted.cityList = [this._personConsultedByCnpj.addressList[0].city];
                    }

                    addressConsulted.complement = this._personConsultedByCnpj.addressList[0].complement || '';
                    addressConsulted.neighborhood = this._personConsultedByCnpj.addressList[0].neighborhood || '';
                    addressConsulted.street = this._personConsultedByCnpj.addressList[0].street || '';
                    addressConsulted.streetNumber = this._personConsultedByCnpj.addressList[0].streetNumber || '';
                    addressConsulted.useInInvoice = this._personConsultedByCnpj.addressList[0].useInInvoice || true;
                    addressConsulted.zipCode = this._personConsultedByCnpj.addressList[0].zipCode || '';
                    addressConsulted.type = 'RESIDENTIAL';
                    this.person.addressList = [addressConsulted];
                }
            }

            this.loading = false;
            this.findCnpjOrCpfChange.emit();
            this.addSuccessMessage(INJECTOR.get(APP_MESSAGES).SUCCESS);
            this.changeDetectorRef.detectChanges();
            return Promise.resolve(true);
        } catch (e) {
            this.loading = false;
            this.handleError(e);
            return Promise.reject(e);
        }
    }

    public refreshPersonByPersonConsultedCnpj(): void {
        try {
            if (
                this.disabled ||
                this.loading ||
                this.isViewing() ||
                this.isCreating() ||
                !this.hasPermissionForOperation() ||
                ((this.typeRegister !== 'CLIENT') && (this.typeRegister !== 'PROVIDER') && (this.typeRegister !== 'REPRESENTATIVE_PROVIDER'))
            ) {
                return;
            }
            if (StringUtil.isEmpty(this.person.legalPerson.cnpj)) {
                this.addWarningMessage('Preencha o campo para realizar a consulta');
                return;
            }
            if (!DocumentUtil.isValidCnpj(this.person.legalPerson.cnpj)) {
                this.addWarningMessage('CNPJ informado é inválido');
                return;
            }

            this._setPersonConsultedByCnpjInPerson();
        } catch (e) {
            this.loading = false;
            this.handleError(e);
        }
    }

    public validateTypePerson(typePerson: TypePersonEnum | TypePerson): void {
        if (StringUtil.isEmpty(typePerson)) {
            return;
        }

        if (typePerson !== 'LEGAL_PERSON') {
            this.person.birthDate = null;
            this.person.legalPerson = null;

            if ((typePerson === 'FOREIGN_PERSON')) {
                this.person.typePerson = 'FOREIGN_PERSON';

                if (!ArrayUtil.isEmpty(this.person.addressList)) {
                    for (let i = 0; i < this.person.addressList.length; i++) {
                        this.person.addressList[i].country = null;
                        this.person.addressList[i].city = null;
                        this.person.addressList[i].state = null;
                    }
                }
            } else {
                this.person.typePerson = 'NATURAL_PERSON';
            }

            if (ObjectUtil.isNull(this.person.naturalPerson)) {
                this.person.naturalPerson = new RegistrationNaturalPerson();
            }
        } else {
            this.person.typePerson = 'LEGAL_PERSON';
            this.person.naturalPerson = null;
            this.person.birthDate = new Date();

            if (ObjectUtil.isNull(this.person.legalPerson)) {
                this.person.legalPerson = new RegistrationLegalPerson();

                // Mudou o lineOfBusiness para a entidade da empresa
                // if (this.typeRegister === 'COMPANY') {
                //     this.person.legalPerson.lineOfBusiness = new RegistrationLineOfBusiness();
                // }
            }
        }
    }

    public removeSpecialCharactersStateRegistration(): void {
        if (this.person.typePerson === 'LEGAL_PERSON' && !StringUtil.isEmpty(this.person.legalPerson.stateRegistration)) {
            this.person.legalPerson.stateRegistration = StringUtil.removeAccents(this.person.legalPerson.stateRegistration);
        } else if (this.person.typePerson === 'NATURAL_PERSON' && !StringUtil.isEmpty(this.person.naturalPerson.documentNumber)) {
            this.person.naturalPerson.documentNumber = StringUtil.removeAccents(this.person.naturalPerson.documentNumber);
        }
    }

    public setNameInFantasyName(): void {
        if (!StringUtil.isEmpty(this.person.name) && StringUtil.isEmpty(this.person.fantasyName)) {
            this.person.fantasyName = this.person.name;
        }
    }

    public hasPermissionForOperation(): boolean {
        if (this.typeRegister === 'CLIENT') {
            return this.hasPermission('CLIENT_MAKE') && this.hasPermission('CLIENT_UPDATE');
        } else if ((this.typeRegister === 'PROVIDER') || (this.typeRegister === 'REPRESENTATIVE_PROVIDER')) {
            return this.hasPermission('PROVIDER_MAKE') && this.hasPermission('PROVIDER_UPDATE');
        }

        return false;
    }

    public openClientSheetCnpjDialog(): void {
        if (!ObjectUtil.isNull(this.person) && !ObjectUtil.isNull(this.person.legalPerson) && !StringUtil.isEmpty(this.person.legalPerson.cnpj)) {
            this.baseService.dialogService.open({
                componentOrTemplateRef: GIPIClientSheetCnpjDialogComponent,
                data: this.person.legalPerson.cnpj,
                width: '55%',
                height: '90%',
            });
        }
    }

    public viewConnectConsult(): void {
        try {
            if (!this._connectConfigEnabled$.getValue()) {
                this.addWarningMessage('Para prosseguir com a consulta da análise de crédito, configure a integração com a Connect SA');
                return;
            }

            const isNaturalPerson: boolean = (!ObjectUtil.isNull(this.person) && !ObjectUtil.isNull(this.person.naturalPerson));
            const cpfOrCnpj: string = (isNaturalPerson ? this.person.naturalPerson.cpf : this.person.legalPerson.cnpj);

            if (StringUtil.isEmpty(cpfOrCnpj)) {
                this.addWarningMessage(`${isNaturalPerson ? 'CPF' : 'CNPJ'} não informado. Para prosseguir com a consulta, por favor, preencha o campo`);
                return;
            }

            const connectConsultData: ConnectConsultData = {
                consultBy: 'CPF_OR_CNPJ',
                clientId: null,
                cpfOrCnpj: cpfOrCnpj,
                consultType: 'BASIC',
                documentType: (this.person.typePerson as TypePerson),
                showDunningNotice: true,
            }

            this.baseService.dialogService.open({
                componentOrTemplateRef: ConnectConsultDialogComponent,
                data: connectConsultData,
                width: '40%',
                height: '42rem',
            });
        } catch (e) {
            this.handleError(e);
        }
    }

}
