import { catchError, map, takeUntil } from 'rxjs/operators';
import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialBankAccountTransferService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.bankAccountTransfer, 'v1', baseService);
        this.baseService = baseService;
    }
    // validate<T = FinancialBankAccountTransfer>(entity: T): string {
    //     if (ObjectUtil.isNewModel(entity.origin)
    //         || ObjectUtil.isNewModel(entity.destiny)
    //         || ObjectUtil.isNull(entity.amount)
    //         || ObjectUtil.isNull(entity.date)) {
    //         throw new Error('Campos obrigatórios (*) não informados');
    //     }
    //     if (entity.origin.id === entity.destiny.id) {
    //         throw new Error('A conta de destino deve ser diferente da conta de origem');
    //     }
    //     if (entity.amount > entity.origin.currentBalance) {
    //         throw new Error('Transferência não autorizada. O valor que está tentando transferir excede o saldo da conta');
    //     }
    // }
    transfer(entity) {
        return this.baseService.httpClient.post(this.url('transfer'), entity, this.options()).pipe(map(this.handleMapper), takeUntil(this.handleDestroy), catchError(this.handleError));
    }
}
FinancialBankAccountTransferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialBankAccountTransferService_Factory() { return new FinancialBankAccountTransferService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialBankAccountTransferService, providedIn: "root" });
