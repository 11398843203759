import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIAbstractCrudService, GIPIBaseService, GIPIPageModel, GIPISortModel, ObjectUtil, StringUtil } from '@gipisistemas/ng-core';
import { RegistrationBankAccountType } from '../enums/bank-account-type.enum';
import { RegistrationBankAccount } from '../models/bank-account.model';
import { RegistrationBankAccountFilterDTO } from '../models/dto/bank-account-filter.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationBankAccountService extends GIPIAbstractCrudService<RegistrationBankAccount, RegistrationBankAccountFilterDTO> {

    constructor(protected baseService: GIPIBaseService,) {
        super(RegistrationPaths.bankAccount, 'v1', baseService);
    }

    findByValue<T = any>(value: string, page: number, size?: number, sort?: GIPISortModel, version?: string, type?: RegistrationBankAccountType): Observable<GIPIPageModel<T>> {
        let lValue: string = value;
        if (value) {
            lValue = StringUtil.removeAccents(value).trim();
        } else {
            lValue = '';
        }
        if ((!page) || (page && (page < 0))) {
            page = 0;
        }
        if ((!size) || (size && (size <= 0))) {
            size = 10;
        }
        let lSort: string = '';
        if (!ObjectUtil.isNull(sort) && !StringUtil.isEmpty(sort.field)) {
            lSort = `&sort=${sort.field},${sort.direction}`
        }
        let lType: string = '';
        if (!StringUtil.isEmpty(type)) {
            lType = `&type=${type}`;
        }

        return this.baseService.httpClient.get(this.url(`find-by-value?page=${page}&size=${size}${lType}${lSort}&value=${lValue}`, version), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    existConciliation(id: number): Observable<boolean> {
        return this.baseService.httpClient.get(this.url(`exist-conciliation/${String(id)}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

    existMovements(bankAccountId: number): Observable<boolean> {
        return this.baseService.httpClient.get(this.url(`exist-movements/${String(bankAccountId)}`), this.options()).pipe(
            map(this.handleMapper),
            takeUntil(this.handleDestroy),
            catchError(this.handleError),
        );
    }

}
