import { FinancialPaths } from '@gipi-paths/financial.paths';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import * as i0 from "@angular/core";
import * as i1 from "@gipisistemas/ng-core";
export class FinancialCustodyService extends GIPIAbstractCrudService {
    constructor(baseService) {
        super(FinancialPaths.custody, 'v1', baseService);
        this.baseService = baseService;
    }
}
FinancialCustodyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FinancialCustodyService_Factory() { return new FinancialCustodyService(i0.ɵɵinject(i1.GIPIBaseService)); }, token: FinancialCustodyService, providedIn: "root" });
